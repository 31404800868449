<template>
  <div class="row">
    <div v-for="(customField, index) in customFields" :key="index"
      :class="{ 'col-md-12': fullWidth, 'col-md-4': !fullWidth }">
      <TextInput v-if="customField.type === 1" v-model="iValue[customField.id]" :name="customField.name"
        :label="customField.name" :margin-bottom="marginBottom" @change="() => $emit('change')" />
      <BirthdayInput v-if="customField.type === 2" v-model="iValue[customField.id]" :label="customField.name" />
    </div>
  </div>
</template>
    
<script>
export default {
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    marginBottom: {
      type: Number,
      default: undefined,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
    }
  },

  computed: {
    iValue: {
      get() { return this.value },
      set(v) {
        this.$emit('input', v)
      },
    },
    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  mounted() {
  },

  methods: {
  }
}
</script>
    