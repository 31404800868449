<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="createForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(createContact)">
                <div class="row">
                  <div class="col-md-4">
                    <TextInput v-model="contact.first_name" name="first_name" label="First Name" />
                  </div>
                  <div class="col-md-4">
                    <TextInput v-model="contact.last_name" name="last_name" label="Last Name" />
                  </div>
                  <div class="col-md-4">
                    <TextInput v-model="contact.email" name="email" rules="email" label="Email"
                      @changed="onChangeField" />
                  </div>
                  <div class="col-md-4">
                    <PhoneInput v-model="contact.phone" name="phone" rules="phone" label="Phone number"
                      :only-countries="[]" @changed="onChangeField" />
                  </div>
                  <div class="col-md-4">
                    <TextInput v-model="contact.business_name" name="business_name" label="Business Name" />
                  </div>
                  <div class="col-md-4">
                    <TextInput v-model="contact.website_url" name="website_url" label="Website URL" />
                  </div>
                  <div class="col-md-4">
                    <BirthdayInput v-model="contact.birthday" />
                  </div>
                  <div class="col-md-4">
                    <BirthdayInput v-model="contact.anniversary" label="Anniversary" />
                  </div>
                </div>
                <CustomFields v-model="contact.custom_fields" />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Keywords</label>
                      <multiselect v-model="contact.keywords" :options="keywords" :multiple="true" track-by="id"
                        label="name" placeholder="Select keywords"></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>
                        Automation
                        <span v-b-tooltip.hover class="cursor-pointer"
                          title="Run selected automations when contact is being created." style="vertical-align: middle;">
                          <i class="uil uil-question-circle"></i>
                        </span>
                      </label>
                      <multiselect v-model="contact.automation_id" :options="automations" :multiple="false" track-by="id"
                        placeholder="Select automation" label="name"></multiselect>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <b-button variant="primary" :disabled="loadingCreate || (invalid && touched)" type="submit"
                      class="mr-2">
                      <b-spinner v-if="loadingCreate" small />
                      <span v-else><i class="uil uil-check"></i> Create</span>
                    </b-button>
                    <router-link class="btn btn-warning " :to="{ name: 'business.contacts.index' }">
                      <span><i class="uil uil-arrow-left"></i> Back</span>
                    </router-link>
                  </div>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CustomFields from '../../Common/CustomFields.vue'

export default {
  components: { Multiselect, CustomFields, },

  data() {
    return {
      contact: {
        phone: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        anniversary: '',
        business_name: '',
        website_url: '',
        keywords: [],
        custom_fields: {},
      },
      loadingCreate: false,
      keywords: [],
      automations: [],
    }
  },

  mounted() {
    this.getKeywords()
    this.getAutomations()
  },

  methods: {
    onChangeField() {
      this.$refs.createForm.reset()
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },

    createContact() {
      this.loadingCreate = true
      const requestParam = Object.assign({}, this.contact)
      if (this.contact.phone) {
        requestParam.phone = this.formatRawNumber(this.contact.phone)
      }

      this.$store
        .dispatch('contact/create', requestParam)
        .then(() => {
          this.$router.push({ name: 'business.contacts.index' })
          this.loadingCreate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    getAutomations() {
      this.$store.dispatch('automation/all')
        .then(automations => {
          this.automations = automations
        })
        .catch(() => {

        })
    },
  },
}
</script>
